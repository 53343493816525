<template>
  <div class="input-group mb-3">
    <span class="input-group-text" id="basic-addon1">
      <i class="fas fa-search"></i>
    </span>
    <input
      type="text"
      class="form-control"
      v-model="searchText"
      placeholder="Type to search..."
    />
  </div>

  <!--Alert Msg-->
  <div v-show="alertMsg" class="mb-3">
    <div class="alert alert-warning fade show" role="alert">
      <i class="fas fa-check-circle"></i> &nbsp; <strong>Sorry!</strong> no jobs
      in this job Category.
    </div>
  </div>
  <!--Alert Msg-->

  <div class="row">
    <div
      v-for="(job, index) in filteredJobs"
      :key="index"
      class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2"
    >
      <div class="card bg-light card-border mb-2">
        <div class="card-body">
          <ImageCarousel :images="job.images" :id="'carousel' + index" />
          <br />

          <span class="text-bold job-title">
            {{ job.jobTitle }}
          </span>
          <br />
          <br />

          <span class="text-bold">Description</span>
          <!--<p class="card-text">{{ job.jobDescription }}</p>-->
          <div v-html="job.jobDescription"></div>

          <p>
            <strong>{{ job.reward }}</strong>
            &nbsp;
            <img src="@/assets/logo.png" alt="TAMIL" width="40" />
          </p>
        </div>
        <div class="card-footer">
          <a
            class="btn btn-sm btn-block btn-danger"
            href="https://app.tamiltoken.org/"
          >
            Apply
          </a>

          <router-link
            class="btn btn-sm btn-block btn-warning text-white"
            :to="{
              path: '/job-details',
              query: { jobId: job.id },
            }"
          >
            View
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageCarousel from "../components/BsCarousel.vue";

export default {
  name: "marketplace-jobs",
  data() {
    return {
      jobs: [],
      searchText: "",
      alertMsg: false,
    };
  },
  components: {
    ImageCarousel,
  },
  computed: {
    filteredJobs() {
      return this.jobs.filter((job) =>
        job.jobTitle.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
  },
  mounted() {
    this.getJobInfo();
  },
  methods: {
    getJobInfo() {
      let loader = this.$loading.show();
      var self = this;
      var filteredJobArray = [];

      $.getJSON(
        "https://s3.ap-southeast-1.amazonaws.com/static.tamiltoken.org/jobs.json",
        function(json) {
          json.forEach(function(item, index) {
            //console.log(item);
            if (item.jobCatId == 10) {
              filteredJobArray.push(item);
            }
          });
          self.jobs = filteredJobArray;
          if (filteredJobArray.length > 0) {
            self.alertMsg = false;
          } else {
            self.alertMsg = true;
          }
        }
      ).fail(function(e) {
        console.log("Json Fail" + e);
      });
      loader.hide();
    },
  },
};
</script>

<style scoped>
.text-bold {
  font-weight: bold;
  font-size: 15px;
}
.job-title {
  font-size: 18px;
}
.card-text {
  font-size: 14px;
}
.input-group-text {
  background-color: rgba(179, 179, 179, 0.993);
}
.card-border {
  border: 1px solid #ff9900;
}
.card {
  height: 100%;
}
.card:hover {
  box-shadow: 16px 8px 16px 8px rgba(0, 0, 0, 0.2);
  opacity: 0.8;
}
</style>
